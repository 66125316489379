import {
  ExitToApp as ExitToAppIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
  useTheme,
} from "@mui/material";

import { MouseEvent, useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ThemeContext } from "../../contexts/ThemeContext/ThemeContext";
import { UserContext } from "../../contexts/UserContext/UserContext";
import { auth } from "../../firebase";
import blacklogo from "../../resources/onymos_logo.png";
import whitelogo from "../../resources/onymos_logo_white.png";

/*
 * This component is used to display the header, which contains the Onymos logo, dashboard tab, and user menu.
 * It is used in the App component.
 */
const Header = () => {
  const theme = useTheme();
  const logo = theme.palette.mode === "dark" ? whitelogo : blacklogo;
  const themeMode = useContext(ThemeContext).themeMode;

  const location = useLocation();

  const { user, setUser } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [navigationAnchorEl, setNavigationAnchorEl] =
    useState<null | HTMLElement>(null);

  const handleMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNavigation = (event: MouseEvent<HTMLButtonElement>) => {
    setNavigationAnchorEl(event.currentTarget);
  };

  const handleNavigationClose = () => {
    setNavigationAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await auth.signOut();
    setUser(null);
    handleClose();
  };

  const getBorder = (path: string) => {
    if (location.pathname === path) {
      if (themeMode === "dark") return "2px solid white";
      else return "2px solid black";
    }
    return undefined;
  };

  return (
    user && (
      <>
        <AppBar
          position="static"
          sx={{
            background: theme.palette.background.default,
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <Toolbar sx={{ pt: 2, pb: 2 }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => window.open("https://www.onymos.com", "_blank")}
              sx={{
                display: { xs: "none", md: "flex" },
                mr: 1,
                borderRadius: 0,
                padding: 1,
              }}
            >
              <img
                src={logo}
                alt="Onymos logo"
                style={{
                  width: "150px",
                  marginRight: "8px",
                  objectFit: "contain",
                }}
              />
            </IconButton>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="navigation"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleNavigation}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Tabs value={false} indicatorColor="primary">
                <Tab
                  label="Dashboard"
                  component={Link}
                  to="/dashboard"
                  sx={{
                    color: theme.palette.text.primary,
                    borderBottom: getBorder("/dashboard"),
                  }}
                />
              </Tabs>
            </Box>

            <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                sx={{ borderRadius: 0 }}
              >
                <Avatar sx={{ width: "40px", height: "40px" }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={!!anchorEl}
                onClose={handleClose}
              >
                <MenuItem component={Link} to="/settings" onClick={handleClose}>
                  <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                  </ListItemIcon>
                  Settings
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <ExitToAppIcon fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>

              <Menu
                id="navigation-appbar"
                anchorEl={navigationAnchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={!!navigationAnchorEl}
                onClose={handleNavigationClose}
              >
                <MenuItem
                  component={Link}
                  to="/dashboard"
                  onClick={handleNavigationClose}
                >
                  Dashboard
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
      </>
    )
  );
};

export default Header;
