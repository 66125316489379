import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";

import Grid from "@mui/material/Unstable_Grid2";

import { Close } from "@mui/icons-material";

import { Dayjs } from "dayjs";
import { DashboardContext } from "../../../../contexts/DashboardContext/DashboardContext";
import { FilterOptionsContext } from "../../../../contexts/FilterOptionsContext/FilterOptionsContext";
import { applyFiltersAndSearch, handleSearch } from "../../Utils/filterSearch";
import { LogVirtuosoTableData } from "../../Utils/tableDataFetch";
import { rowColumns } from "../ColumnsConfig";
import ColumnFilterPopover from "./Popovers/ColumnFilterPopover";
import ExportPopover from "./Popovers/ExportPopover";
import FilterOptionsPopover from "./Popovers/FilterOptionsPopover";
import SortedColumnPopover from "./Popovers/SortedColumnPopover";
import SummaryGraph from "./Summary/Summary";
import SecondRow from "./SecondRow";
import FirstRow from "./FirstRow";
interface ControlProps {
  rows: LogVirtuosoTableData[];
  height: string;
  handleRefresh: () => void;
}

/*
 * This component is used to display all the different table options above the virtualized table which contains options for
 * exporting the table data, filtering the table data, searching it, etc.
 * It is used in the Dashboard component.
 */
const Controls = ({ rows, height, handleRefresh }: ControlProps) => {
  const [searchInput, setSearchInput] = useState("");
  const isMobile = useMediaQuery("(max-width: 600px)");

  const [filters, setFilters] = useState({
    success: false,
    errors: false,
    last1DayCompleted: false,
    last2DaysCompleted: false,
    last1DaySubmitted: false,
    last2DaysSubmitted: false,
  });

  const clearFilters = () => {
    setSearchInput("");
    setStartDateCompleted(null);
    setEndDateCompleted(null);
    setStartDateSubmitted(null);
    setEndDateSubmitted(null);
    setFilters({
      success: false,
      errors: false,
      last1DayCompleted: false,
      last2DaysCompleted: false,
      last1DaySubmitted: false,
      last2DaysSubmitted: false,
    });
  };

  const [isGraphOpen, setIsGraphOpen] = useState(false);

  const openGraph = () => setIsGraphOpen(true);
  const closeGraph = () => setIsGraphOpen(false);

  const { sortConfig, setSortConfig } = useContext(DashboardContext);
  const { selectedRows, setSelectedRows } = useContext(DashboardContext);
  const { sortedColumn, setSortedColumn } = useContext(DashboardContext);
  const { filteredRows, setFilteredRows } = useContext(DashboardContext);

  const [startDateCompleted, setStartDateCompleted] = useState<Dayjs | null>(
    null
  );
  const [endDateCompleted, setEndDateCompleted] = useState<Dayjs | null>(null);
  const [startDateSubmitted, setStartDateSubmitted] = useState<Dayjs | null>(
    null
  );
  const [endDateSubmitted, setEndDateSubmitted] = useState<Dayjs | null>(null);
  const [sortedColumnAnchorEl, setSortedColumnAnchorEl] =
    useState<null | HTMLElement>(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [columnAnchorEl, setColumnAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [exportAnchorEl, setExportAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const seletedRowsData = rows.filter((row) => selectedRows.has(row.id));

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };
  const handleColumnClick = (event: React.MouseEvent<HTMLElement>) => {
    setColumnAnchorEl(event.currentTarget);
  };
  const handleColumnClose = () => {
    setColumnAnchorEl(null);
  };
  const handleSortClick = (event: React.MouseEvent<HTMLElement>) => {
    setSortConfig({
      key: sortConfig.key,
      direction: sortConfig.direction === "asc" ? "desc" : "asc",
    });
  };

  const handleExportClick = (event: React.MouseEvent<HTMLElement>) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setExportAnchorEl(null);
  };

  const handleStartDateChangeCompleted = (date: Dayjs | null) => {
    setStartDateCompleted(date);
  };
  const handleEndDateChangeCompleted = (date: Dayjs | null) => {
    setEndDateCompleted(date);
  };
  const handleStartDateChangeSubmitted = (date: Dayjs | null) => {
    setStartDateSubmitted(date);
  };
  const handleEndDateChangeSubmitted = (date: Dayjs | null) => {
    setEndDateSubmitted(date);
  };
  const handleSortedColumnClick = (event: React.MouseEvent<HTMLElement>) => {
    setSortedColumnAnchorEl(event.currentTarget);
  };
  const handleSortedColumnClose = () => {
    setSortedColumnAnchorEl(null);
  };

  const [selectedColumns, setSelectedColumns] = useState<{
    [key: string]: boolean;
  }>(
    rowColumns.reduce((acc, column) => ({ ...acc, [column.dataKey]: true }), {})
  );
  const handleColumnSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedColumns({
      ...selectedColumns,
      [event.target.name]: event.target.checked,
    });
  };
  const handleSelectAll = () => {
    if (selectedRows.size > 0) {
      setSelectedRows(new Set());
    } else {
      const newSelectedRows = new Set(filteredRows.map((row) => row.id));
      setSelectedRows(newSelectedRows);
    }
  };
  const applyFiltersAndSorting = useCallback(() => {
    const newFilteredRows = applyFiltersAndSearch(
      rows,
      searchInput,
      sortConfig,
      startDateCompleted,
      endDateCompleted,
      startDateSubmitted,
      endDateSubmitted,
      selectedColumns,
      filters
    );

    setFilteredRows(newFilteredRows);
  }, [
    rows,
    setFilteredRows,
    searchInput,
    sortConfig,
    startDateCompleted,
    endDateCompleted,
    startDateSubmitted,
    endDateSubmitted,
    selectedColumns,
    filters,
  ]);

  const handleClickSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSearch(setSearchInput, applyFiltersAndSorting, event);
  };

  useEffect(() => {
    applyFiltersAndSorting();
  }, [applyFiltersAndSorting]);

  return (
    <FilterOptionsContext.Provider
      value={{
        startDateCompleted,
        handleStartDateChangeCompleted,
        endDateCompleted,
        handleEndDateChangeCompleted,
        startDateSubmitted,
        handleStartDateChangeSubmitted,
        endDateSubmitted,
        handleEndDateChangeSubmitted,
        filters,
        setFilters,
        clearFilters,
        filterAnchorEl,
        handleFilterClose,
      }}
    >
      <Grid
        container
        alignItems="center"
        xs={12}
        height={height}
        marginTop={3}
        minHeight="125px"
        flexDirection={isMobile ? "column" : "row"}
      >
        <FirstRow
          isMobile={isMobile}
          searchInput={searchInput}
          handleClickSearch={handleClickSearch}
          handleColumnClick={handleColumnClick}
          columnAnchorEl={columnAnchorEl}
          openGraph={openGraph}
          handleExportClick={handleExportClick}
        />

        <Divider flexItem sx={{ width: "100%", mb: 2 }} />

        <SecondRow
          isMobile={isMobile}
          handleFilterClick={handleFilterClick}
          handleSortedColumnClick={handleSortedColumnClick}
          sortedColumn={sortedColumn}
          selectedRows={selectedRows}
          rows={rows}
          handleSelectAll={handleSelectAll}
          filteredRows={filteredRows}
          handleSortClick={handleSortClick}
          sortConfig={sortConfig}
          handleRefresh={handleRefresh}
        />

        <ExportPopover
          anchorEl={exportAnchorEl}
          onClose={handleExportClose}
          rows={rows}
          filteredRows={filteredRows}
          selectedRows={seletedRowsData}
        />
        <SortedColumnPopover
          sortedColumnAnchorEl={sortedColumnAnchorEl}
          handleSortedColumnClose={handleSortedColumnClose}
          setSortedColumn={setSortedColumn}
          sortedColumn={sortedColumn}
          sortConfig={sortConfig}
          setSortConfig={setSortConfig}
        />
        <FilterOptionsPopover />

        <ColumnFilterPopover
          columnAnchorEl={columnAnchorEl}
          handleColumnClose={handleColumnClose}
          handleColumnSelect={handleColumnSelect}
          selectedColumns={selectedColumns}
        />

        <Dialog open={isGraphOpen} onClose={closeGraph} fullWidth maxWidth="lg">
          <DialogTitle>
            Summary
            <IconButton
              style={{ position: "absolute", right: "8px", top: "8px" }}
              onClick={closeGraph}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <SummaryGraph
              rows={filteredRows}
              startDateCompleted={startDateCompleted}
              handleStartDateChangeCompleted={handleStartDateChangeCompleted}
              endDateCompleted={endDateCompleted}
              handleEndDateChangeCompleted={handleEndDateChangeCompleted}
              startDateSubmitted={startDateSubmitted}
              handleStartDateChangeSubmitted={handleStartDateChangeSubmitted}
              endDateSubmitted={endDateSubmitted}
              handleEndDateChangeSubmitted={handleEndDateChangeSubmitted}
              filters={filters}
              setFilters={setFilters}
              clearFilters={clearFilters}
            />
          </DialogContent>
        </Dialog>
      </Grid>
    </FilterOptionsContext.Provider>
  );
};

export default Controls;
