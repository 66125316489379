import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useContext } from "react";
import { FilterOptionsContext } from "../../../../../contexts/FilterOptionsContext/FilterOptionsContext";
import CustomCheckbox from "../../CustomCheckbox";
import FilterDatePicker from "../FilterDatePicker";
import { HeaderTypography } from "../../../../../App";
import dayjs from "dayjs";
const FilterOptionsPopover = () => {
  const {
    filters,
    setFilters,
    clearFilters,
    filterAnchorEl,
    handleFilterClose,
    handleStartDateChangeCompleted,
    handleEndDateChangeCompleted,
    handleStartDateChangeSubmitted,
    handleEndDateChangeSubmitted,
    startDateCompleted,
    endDateCompleted,
    startDateSubmitted,
    endDateSubmitted,
  } = useContext(FilterOptionsContext);

  const theme = useTheme();

  const checkboxInfo = {
    last1DaySubmitted: {
      subtractDays: 1,
      handleStartDateChange: handleStartDateChangeSubmitted,
      handleEndDateChange: handleEndDateChangeSubmitted,
      otherCheckbox: "last2DaysSubmitted",
    },
    last2DaysSubmitted: {
      subtractDays: 2,
      handleStartDateChange: handleStartDateChangeSubmitted,
      handleEndDateChange: handleEndDateChangeSubmitted,
      otherCheckbox: "last1DaySubmitted",
    },
    last1DayCompleted: {
      subtractDays: 1,
      handleStartDateChange: handleStartDateChangeCompleted,
      handleEndDateChange: handleEndDateChangeCompleted,
      otherCheckbox: "last2DaysCompleted",
    },
    last2DaysCompleted: {
      subtractDays: 2,
      handleStartDateChange: handleStartDateChangeCompleted,
      handleEndDateChange: handleEndDateChangeCompleted,
      otherCheckbox: "last1DayCompleted",
    },
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name as keyof typeof prevFilters]: checked,
    }));

    const info = checkboxInfo[name as keyof typeof checkboxInfo];
    if (info) {
      info.handleStartDateChange(
        checked ? dayjs().subtract(info.subtractDays, "day") : null
      );
      info.handleEndDateChange(checked ? dayjs() : null);

      if (checked) {
        setFilters((prevFilters) => ({
          ...prevFilters,
          [info.otherCheckbox]: false,
        }));
      }
    }
  };

  const resetDatePicker = (type: string) => {
    if (type === "completed") {
      handleStartDateChangeCompleted(null);
      handleEndDateChangeCompleted(null);
    } else {
      handleStartDateChangeSubmitted(null);
      handleEndDateChangeSubmitted(null);
    }
  };

  return (
    <Popover
      open={!!filterAnchorEl}
      anchorEl={filterAnchorEl}
      onClose={handleFilterClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box width={350}>
        <HeaderTypography
          sx={{
            p: 2,
            fontSize: "16px",
            fontWeight: 700,
          }}
        >
          Filter
        </HeaderTypography>
        <Divider flexItem sx={{ width: "100%", mb: 2 }} />
        <Grid container>
          <Grid xs={12} paddingLeft={2} paddingRight={1}>
            <Typography>Status Type</Typography>
          </Grid>
          <Grid xs={6} paddingLeft={2.5}>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={filters.success}
                  onChange={handleCheckboxChange}
                  name="success"
                  indeterminate={false}
                />
              }
              label="Success"
            />
          </Grid>
          <Grid xs={6} paddingLeft={1.5}>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={filters.errors}
                  onChange={handleCheckboxChange}
                  name="errors"
                  indeterminate={false}
                />
              }
              label="Errors"
            />
          </Grid>
          <Divider flexItem sx={{ width: "100%", mb: 2, mt: 1 }} />

          <Grid xs={9} paddingLeft={2} paddingBottom={2}>
            <Typography>Submitted At</Typography>
          </Grid>
          <Grid xs={3} paddingLeft={2}>
            <Button
              disableRipple
              color="primary"
              sx={{
                pt: 0.25,
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
              onClick={() => resetDatePicker("submitted")}
            >
              <Typography variant="body2">Reset</Typography>
            </Button>
          </Grid>
          <Grid xs={6} padding={2} paddingRight={1} paddingBottom={1}>
            <FilterDatePicker
              label="From:"
              value={startDateSubmitted}
              onChange={handleStartDateChangeSubmitted}
            />
          </Grid>
          <Grid xs={6} padding={2} paddingLeft={1} paddingBottom={1}>
            <FilterDatePicker
              label="To:"
              value={endDateSubmitted}
              onChange={handleEndDateChangeSubmitted}
            />
          </Grid>
          <Grid xs={6} paddingLeft={2.5}>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={filters.last1DaySubmitted}
                  onChange={handleCheckboxChange}
                  name="last1DaySubmitted"
                  indeterminate={false}
                />
              }
              label="Previous Day"
            />
          </Grid>
          <Grid xs={6} paddingLeft={1.5}>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={filters.last2DaysSubmitted}
                  onChange={handleCheckboxChange}
                  name="last2DaysSubmitted"
                  indeterminate={false}
                />
              }
              label="Last 2 Days"
            />
          </Grid>
          <Divider flexItem sx={{ width: "100%", mt: 1, mb: 2 }} />
          <Grid xs={9} paddingLeft={2} paddingBottom={2}>
            <Typography>Completed At</Typography>
          </Grid>
          <Grid xs={3} paddingLeft={2}>
            <Button
              disableRipple
              color="primary"
              sx={{
                pt: 0.25,
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
              onClick={() => resetDatePicker("completed")}
            >
              <Typography variant="body2">Reset</Typography>
            </Button>
          </Grid>
          <Grid xs={6} padding={2} paddingRight={1} paddingBottom={1}>
            <FilterDatePicker
              label="From:"
              value={startDateCompleted}
              onChange={handleStartDateChangeCompleted}
            />
          </Grid>
          <Grid xs={6} padding={2} paddingLeft={1} paddingBottom={1}>
            <FilterDatePicker
              label="To:"
              value={endDateCompleted}
              onChange={handleEndDateChangeCompleted}
            />
          </Grid>
          <Grid xs={6} paddingLeft={2.5}>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={filters.last1DayCompleted}
                  onChange={handleCheckboxChange}
                  name="last1DayCompleted"
                  indeterminate={false}
                />
              }
              label="Previous Day"
            />
          </Grid>
          <Grid xs={6} paddingLeft={1.5}>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={filters.last2DaysCompleted}
                  onChange={handleCheckboxChange}
                  name="last2DaysCompleted"
                  indeterminate={false}
                />
              }
              label="Last 2 Days"
            />
          </Grid>

          <Divider flexItem sx={{ width: "100%", mt: 1, mb: 1 }} />

          <Grid xs={12} padding={2} display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={clearFilters}
              sx={{
                borderRadius: "10px",
                mr: 1,
                bgcolor: "white",
                color: theme.palette.secondary.main,
                border: "1px solid black",
              }}
            >
              Reset All
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  );
};

export default FilterOptionsPopover;
