// FirstRow.tsx
import React from "react";
import { Box } from "@mui/material";
import { HeaderTypography } from "../../../../App";
import ExportButton from "./Buttons/Export";
import SummaryButton from "./Buttons/Summary";
import SearchBox from "./SearchBox";
import Grid from "@mui/material/Unstable_Grid2";

interface FirstRowProps {
  isMobile: boolean;
  searchInput: string;
  handleClickSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleColumnClick: (event: React.MouseEvent<HTMLElement>) => void;
  columnAnchorEl: HTMLElement | null;
  openGraph: () => void;
  handleExportClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const FirstRow: React.FC<FirstRowProps> = ({
  isMobile,
  searchInput,
  handleClickSearch,
  handleColumnClick,
  columnAnchorEl,
  openGraph,
  handleExportClick,
}) => {
  return (
    <Grid
      container
      alignItems="center"
      xs={12}
      flexDirection={isMobile ? "column" : "row"}
    >
      <Grid
        xs={12}
        md={6}
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        alignItems="center"
      >
        <HeaderTypography variant="h2" sx={{ mr: isMobile ? 0 : 5 }}>
          LogViewer
        </HeaderTypography>
      </Grid>
      <Grid
        xs={12}
        md={6}
        display="flex"
        justifyContent={isMobile ? "center" : "flex-end"}
        alignItems="center"
        marginTop={isMobile ? 2 : 0}
      >
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          alignItems="center"
          gap={2}
          mb={isMobile ? 2 : 0}
        >
          <SearchBox
            value={searchInput}
            onChange={handleClickSearch}
            onColumnClick={handleColumnClick}
            columnAnchorEl={columnAnchorEl}
          />
          <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
            <SummaryButton onClick={openGraph} />
            <ExportButton onClick={handleExportClick} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FirstRow;
