// SecondRow.tsx
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";

import { Box, Stack, Typography } from "@mui/material";
import CustomCheckbox from "../CustomCheckbox";
import FilterButton from "./Buttons/Filter";
import RefreshIconButton from "./Buttons/Refresh";
import SortButton from "./Buttons/Sort";
import SortIconButton from "./Buttons/SortIcon";

interface SecondRowProps {
  isMobile: boolean;
  handleFilterClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleSortedColumnClick: (event: React.MouseEvent<HTMLElement>) => void;
  sortedColumn: { label: string } | null;
  selectedRows: Set<any>;
  rows: any[];
  handleSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  filteredRows: any[];
  handleSortClick: (event: React.MouseEvent<HTMLElement>) => void;
  sortConfig: any;
  handleRefresh: (event: React.MouseEvent<HTMLElement>) => void;
}

const SecondRow: React.FC<SecondRowProps> = ({
  isMobile,
  handleFilterClick,
  handleSortedColumnClick,
  sortedColumn,
  selectedRows,
  rows,
  handleSelectAll,
  filteredRows,
  handleSortClick,
  sortConfig,
  handleRefresh,
}) => {
  let succeededCount = 0;
  let errorsCount = 0;
  let naCount = 0;

  filteredRows.forEach((row) => {
    const status = row.status.trim().toLowerCase();
    if (status === "succeeded") {
      succeededCount++;
    } else if (status !== "n/a") {
      errorsCount++;
    } else {
      naCount++;
    }
  });

  if (isMobile) {
    return (
      <Grid
        container
        xs={12}
        alignItems="center"
        justifyContent="space-between"
        flexDirection="column"
      >
        <Stack spacing={1}>
          <Box display="flex" alignItems="center" gap={1}>
            <FilterButton onClick={handleFilterClick} />
            <SortButton
              onClick={handleSortedColumnClick}
              sortedColumn={sortedColumn ? { label: sortedColumn.label } : null}
            />
          </Box>

          <CustomCheckbox
            checked={selectedRows.size === rows.length && rows.length > 0}
            indeterminate={
              selectedRows.size > 0 && selectedRows.size < rows.length
            }
            onChange={handleSelectAll}
            name="selectAll"
          />

          <Box textAlign="center">
            <Typography>
              {selectedRows.size} of {filteredRows.length} selected
            </Typography>
            <Typography variant="body2" marginTop={1}>
              {succeededCount} succeeded, {errorsCount} errors, {naCount} N/A
            </Typography>
          </Box>
          <Box textAlign="center">
            <SortIconButton onClick={handleSortClick} sortConfig={sortConfig} />
            <RefreshIconButton onClick={handleRefresh} />
          </Box>
        </Stack>
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        xs={12}
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
      >
        <Grid
          xs={6}
          container
          direction="row"
          alignItems="center"
          marginLeft={2}
          marginRight={-2}
        >
          <Grid>
            <CustomCheckbox
              checked={selectedRows.size === rows.length && rows.length > 0}
              indeterminate={
                selectedRows.size > 0 && selectedRows.size < rows.length
              }
              onChange={handleSelectAll}
              name="selectAll"
            />
          </Grid>
          <Grid>
            <Box marginLeft={2}>
              <Typography>
                {selectedRows.size} of {filteredRows.length} selected
              </Typography>
              <Typography variant="body2">
                {succeededCount} succeeded, {errorsCount} errors, {naCount} N/A
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid xs={6} container justifyContent="flex-end">
          <FilterButton onClick={handleFilterClick} />
          <SortButton
            onClick={handleSortedColumnClick}
            sortedColumn={sortedColumn ? { label: sortedColumn.label } : null}
          />
          <SortIconButton onClick={handleSortClick} sortConfig={sortConfig} />
          <RefreshIconButton onClick={handleRefresh} />
        </Grid>
      </Grid>
    );
  }
};

export default SecondRow;
