import dayjs, { Dayjs } from "dayjs";
import { TableColumn } from "../Dashboard";
import { LogVirtuosoTableData } from "./tableDataFetch";

/*
 * This function applies all the filters selected by the user for the dashboard table.
 * It prioritizes and applies filters in this order:
 * 1. Search Input
 * 2. Success/Errors
 * 3. Date Range Submitted
 * 4. Date Range Completed
 * 5. Asc/Desc Sorting
 */
export function applyFiltersAndSearch(
  rows: LogVirtuosoTableData[],
  searchInput: string,
  sortConfig: { key: string | null; direction: string },
  startDateCompleted: Dayjs | null,
  endDateCompleted: Dayjs | null,
  startDateSubmitted: Dayjs | null,
  endDateSubmitted: Dayjs | null,
  selectedColumns: { [key: string]: boolean },
  filters: {
    success: boolean;
    errors: boolean;
    last1DayCompleted: boolean;
    last2DaysCompleted: boolean;
    last1DaySubmitted: boolean;
    last2DaysSubmitted: boolean;
  }
): LogVirtuosoTableData[] {
  let newFilteredRows = [...rows];

  if (searchInput !== "") {
    newFilteredRows = newFilteredRows.filter((row) =>
      Object.keys(row).some(
        (columnKey) =>
          (selectedColumns[columnKey] &&
            String(row[columnKey])
              .toLowerCase()
              .includes(searchInput.toLowerCase())) ||
          (columnKey === "documentId" &&
            selectedColumns[columnKey] &&
            (String(row[columnKey])
              .toLowerCase()
              .includes(searchInput.toLowerCase()) ||
              String(row["id"])
                .toLowerCase()
                .includes(searchInput.toLowerCase())))
      )
    );
  }

  if (filters.success && !filters.errors) {
    newFilteredRows = newFilteredRows.filter(
      (row) =>
        typeof row.status === "string" &&
        row.status.trim().toLowerCase() === "succeeded"
    );
  } else if (filters.errors && !filters.success) {
    newFilteredRows = newFilteredRows.filter(
      (row) =>
        typeof row.status === "string" &&
        row.status.trim().toLowerCase() !== "succeeded" &&
        row.status.trim().toLowerCase() !== "n/a"
    );
  }

  if (startDateSubmitted || endDateSubmitted) {
    newFilteredRows = newFilteredRows.filter((row) => {
      if (row.submittedAt) {
        const rowDate = dayjs(row.submittedAt);
        const endOfDayEndDate = endDateSubmitted
          ? endDateSubmitted.endOf("day")
          : null;

        return (
          (!startDateSubmitted || rowDate >= startDateSubmitted) &&
          (!endOfDayEndDate || rowDate <= endOfDayEndDate)
        );
      }
      return false;
    });
  }

  if (startDateCompleted || endDateCompleted) {
    newFilteredRows = newFilteredRows.filter((row) => {
      if (row.completedAt) {
        const rowDate = dayjs(row.completedAt);
        const endOfDayEndDate = endDateCompleted
          ? endDateCompleted.endOf("day")
          : null;

        return (
          (!startDateCompleted || rowDate >= startDateCompleted) &&
          (!endOfDayEndDate || rowDate <= endOfDayEndDate)
        );
      }
      return false;
    });
  }

  const key = sortConfig.key ?? "";
  newFilteredRows.sort((a, b) => {
    if (a[key] < b[key]) {
      return sortConfig.direction === "asc" ? -1 : 1;
    } else if (a[key] > b[key] || !b[key]) {
      return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });

  return newFilteredRows;
}

/*
 * This function handles when the user types in something new to the search field
 * It takes the event and sets the search input to the value of the event.
 * Then it applies the filters and sorting.
 */
export function handleSearch(
  setSearchInput: (value: string) => void,
  applyFiltersAndSorting: () => void,
  event: React.ChangeEvent<HTMLInputElement>
) {
  setSearchInput(event.target.value);
  applyFiltersAndSorting();
}

/*
 * This function handles when the user clicks the arrows next to the table header
 * It takes the event sets the filter to either asc/desc based on the current config.
 */
export function handleSort(
  column: TableColumn,
  setSortedColumn: (column: TableColumn) => void,
  sortConfig: { key: string | null; direction: "asc" | "desc" },
  setSortConfig: (config: {
    key: string | null;
    direction: "asc" | "desc";
  }) => void
) {
  setSortedColumn(column);
  let direction: "asc" | "desc" = "asc";
  if (sortConfig.key === column.dataKey && sortConfig.direction === "asc") {
    direction = "desc";
  }
  setSortConfig({ key: column.dataKey, direction });
}
