import "@fontsource/comfortaa/";
import "@fontsource/inter/";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/Login/Login";
import { UserContext } from "./contexts/UserContext/UserContext";

import { ThemeProvider, Typography, createTheme, styled } from "@mui/material";
import { User } from "firebase/auth";
import Header from "./components/Header/Header";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Settings from "./components/Settings/Settings";
import { ThemeContext } from "./contexts/ThemeContext/ThemeContext";

export const HeaderTypography = styled(Typography)({
  fontFamily: "Comfortaa",
});

/*
 * This component is used to display the app, which contains the header, login page, and the protected internal page
 */
function App() {
  const [user, setUser] = useState<User | null>(null);
  const [themeMode, setThemeMode] = useState<"light" | "dark">(() => "light");

  const borderRadius = "10px";

  const theme = createTheme({
    palette: {
      mode: themeMode,
      primary: {
        main: "#F07000",
      },
      secondary: {
        main: "#2A2A2A",
      },
      text: {
        primary: themeMode === "dark" ? "#FFFFFF" : "#000000",
      },
    },
    typography: {
      fontFamily: "Inter",
      h2: {
        fontSize: "33px",
        fontWeight: 700,
      },
      body1: {
        fontSize: "14px",
        fontWeight: 500,
      },
      body2: {
        fontSize: "12px",
      },
      button: {
        color: "#FFFFFF",
      },
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: borderRadius,
            color: themeMode === "dark" ? "#FFFFFF" : "#000000",
            border:
              themeMode === "dark" ? "1px solid white" : "1px solid black",
          },
          notchedOutline: {
            border: "none",
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: borderRadius,
            textTransform: "none",
            fontSize: "16px",
            fontFamily: "Comfortaa",
            fontWeight: 700,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontWeight: "bold",
            padding: "8px",
            fontSize: "16px",
            fontFamily: "Comfortaa",

            textTransform: "none",
          },
        },
      },
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "25px",
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            color: "white !important",
            "&:hover": {
              color: "white",
            },
            "&$active": {
              color: "white",
            },
          },
          icon: {
            color: "inherit !important",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily: "Inter",
            fontSize: "14px",
            verticalAlign: "top",
          },
          head: {
            fontWeight: 600,
            fontSize: "14px",
            backgroundColor: "#2A2A2A",
          },
        },
      },
    },
  });

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={theme}>
            <Router>
              <Header />
              <Routes>
                <Route element={<ProtectedRoute />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                </Route>
                <Route element={<ProtectedRoute />}>
                  <Route path="/settings" element={<Settings />} />
                </Route>
                <Route element={<Login />} path="/login" />
                <Route path="*" element={<Navigate to="/login" replace />} />
              </Routes>
            </Router>
          </ThemeProvider>
        </LocalizationProvider>
      </ThemeContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
