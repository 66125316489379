import React, { Dispatch, SetStateAction } from "react";
import { Dayjs } from "dayjs";

interface FilterOptionsProps {
  startDateCompleted: Dayjs | null;
  handleStartDateChangeCompleted: (date: Dayjs | null) => void;
  endDateCompleted: Dayjs | null;
  handleEndDateChangeCompleted: (date: Dayjs | null) => void;
  startDateSubmitted: Dayjs | null;
  handleStartDateChangeSubmitted: (date: Dayjs | null) => void;
  endDateSubmitted: Dayjs | null;
  handleEndDateChangeSubmitted: (date: Dayjs | null) => void;
  filters: { [key: string]: boolean };
  setFilters: Dispatch<
    SetStateAction<{
      success: boolean;
      errors: boolean;
      last1DayCompleted: boolean;
      last2DaysCompleted: boolean;
      last1DaySubmitted: boolean;
      last2DaysSubmitted: boolean;
    }>
  >;

  clearFilters: () => void;
  filterAnchorEl: HTMLElement | null;
  handleFilterClose: () => void;
}

/*
 * This context is used to pass the filter options state to any components that need it for the different FilterPopover options.
 * It is used in the TableOptions component.
 */
export const FilterOptionsContext = React.createContext<FilterOptionsProps>({
  startDateCompleted: null,
  handleStartDateChangeCompleted: () => {},
  endDateCompleted: null,
  handleEndDateChangeCompleted: () => {},
  startDateSubmitted: null,
  handleStartDateChangeSubmitted: () => {},
  endDateSubmitted: null,
  handleEndDateChangeSubmitted: () => {},
  filters: {},
  setFilters: () => {},
  clearFilters: () => {},
  filterAnchorEl: null,
  handleFilterClose: () => {},
});
